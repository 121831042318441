import {
  Link,
  NavLink,
  Outlet,
  Meta,
  Links,
  Scripts,
  ScrollRestoration,
  useNavigation
} from "@remix-run/react";
import type { LinksFunction } from "@remix-run/node";
import Spinner from "./components/Spinner";
import stylesheet from "~/tailwind.css?url";
import { useState, useRef, useEffect } from "react";
import { Disclosure, DisclosureButton } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { API_URL_IMAGEN, URL_MAIN, VITE_DATABASE_CLUSTER } from "./utilidades/constantes";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

const baseMenusNavegacionMovil = [
  { id: 10, name: 'Inicio',title:'Abogados Ecuador app abogados y consultas legales', href: '/' },
  { id: 20, name: 'Consulta Programada', title:'Abogados en Guayaquil, Quito, Cuenca, Manta, Machala, etc',href: '/especialistas' },
  { id: 30, name: 'Consulta Emergente',title:'Ingreso Consulta legal online en Ecuador', href: '/legaltech/consulta-juridica-online' },
 
  { id: 40, name: 'Directorio',title:'Directorio de abogados verificados', href: '/contacts' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

import type { MetaFunction } from "@remix-run/node"; // or "@remix-run/react" for the client-side

export const meta: MetaFunction = () => {
  return [
    { rel: "canonical", href: URL_MAIN },
    { name: "robots", content: "noindex, follow" },
  ];
};
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'


export default function App() {

  const navigationprueba = useNavigation();
  const [currentMenu, setCurrentMenu] = useState<number | null>(null);
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  // Function to reorder mobile menu items
  const reorderMenuItems = (items: any[], currentId: number | null) => {
    const inicioItem = items.find(item => item.name === 'Inicio');
    const otherItems = items.filter(item => item.name !== 'Inicio');

    const currentItem = otherItems.find(item => item.id === currentId);
    const filteredItems = otherItems.filter(item => item.id !== currentId);

    return inicioItem ? [inicioItem, ...(currentId && currentItem ? [currentItem] : []), ...filteredItems] : filteredItems;
  };

  const menusNavegacionMovil = reorderMenuItems(baseMenusNavegacionMovil, currentMenu ?? -1);

  // Reset horizontal scroll position after rendering
  useEffect(() => {
    if (mobileMenuRef.current) {
      mobileMenuRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, [menusNavegacionMovil]);

  return (
    <html lang="es">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Abogados Ecuador",
              "url": "https://abogadosecuador.com.ec",
              "logo": "https://stitch-statichosting-prod.s3.amazonaws.com/62ec15021798f5d543c57f99/lawyers/isotipoazul.png",
              "description": "Abogados especialistas en Ecuador, elige un abogado para tu consulta legal online",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+593-979293801",
                "contactType": "Customer Service",
                "areaServed": "EC",
                "availableLanguage": ["Spanish"]
              }
            }),
          }}
        />
      </head>
      <body>
        {navigationprueba.state === "loading" ? <Spinner /> : null}
       
        <Disclosure as="nav" className="bg-custom-primary">
          {({ open }) => (
            <>
              {/* Desktop Menu */}
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-20 md:h-auto items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </DisclosureButton>
                  </div>
                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <img 
                        className="h-10 w-auto"
                        width={264} height={106}
                        src={`${API_URL_IMAGEN}${VITE_DATABASE_CLUSTER}/logoblanco.png`}
                        alt="Globaldir Abogados Ecuador"
                      />
                    </div>
                    <div className="hidden md:flex sm:ml-6" id="menudoc">
                      {baseMenusNavegacionMovil.map((item) => (
                        <NavLink unstable_viewTransition
                          key={item.id}
                          to={item.href}
                          title={item.title}
                          className={({ isActive }) =>
                            classNames(
                              isActive ? 'bg-custom-gold text-black font-bold' : 'text-gray-300 hover:bg-gray-700 hover:text-custom-gold',
                              'px-3 py-10 text-sm font-medium'
                            )
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Mobile navigation */}
              <nav className="md:hidden bg-custom-primary">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                  <div className="flex overflow-x-auto sm:overflow-x-visible scrollbar-hide" ref={mobileMenuRef}>
                    {menusNavegacionMovil.map((itemms) => (
                      <NavLink unstable_viewTransition
                        key={itemms.id}
                        to={itemms.href}
                        title={itemms.title}
                        className={({ isActive }) =>
                          classNames(
                            isActive ? 'bg-custom-gold text-black font-bold' : 'text-gray-300 hover:bg-gray-700 hover:text-custom-gold',
                            'px-3 py-5 text-base font-medium whitespace-nowrap'
                          )
                        }
                        onClick={() => setCurrentMenu(itemms.id)}
                      >
                        {itemms.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </nav>
            </>
          )}
        </Disclosure>
     
        <div>
          <Outlet />
        </div>
        <div className="relative isolate overflow-hidden bg-custom-primary py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">Directorio de Abogados</h2>
            <p className="mt-4 text-md leading-8 text-gray-300">
            ¿Buscas un abogado de confianza en Guayaquil, Quito, Cuenca, Machala o en cualquier ciudad? ¡No busques más! En GLOBALDIR ABOGADOS ECUADOR, somos el directorio líder en abogados en todo el país. Te ofrecemos una selección exhaustiva de abogados especializados en diversas áreas del derecho, listos para brindarte el asesoramiento y representación que necesitas.


            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
       





            </div>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
  <div className="flex flex-col items-start">
    <dt className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
      <CalendarDaysIcon aria-hidden="true" className="h-6 w-6 text-white" />
    </dt>
    <dd className="mt-4 font-semibold text-white">Consultas Semanales</dd>
    <dd className="mt-2 leading-7 text-gray-400">
      Todas las semanas nuevas consultas legales ingresadas al directorio de Abogados.
    </dd>
  </div>
  <div className="flex flex-col items-start">
    <dt className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
      <HandRaisedIcon aria-hidden="true" className="h-6 w-6 text-white" />
    </dt>
    <dd className="mt-4 font-semibold text-white">No spam</dd>
    <dd className="mt-2 leading-7 text-gray-400">
      Respetamos tu privacidad y nunca hacemos spam. Tus datos están seguros con nosotros.
    </dd>
  </div>
</dl>

        </div>
      </div>
      <div aria-hidden="true" className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6">
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <dl>
  <dt className="mt-10 pl-5 md:pl-20 font-semibold text-white">
    TECFRONT S.A.
  </dt>
  <dd className="mt-2 pl-5 md:pl-20 text-white">
    <Link title="Políticas de privacidad complementarias de Globaldir Abogados Ecuador" to={"/politicas-de-privacidad"}>Política de Privacidad</Link>
  </dd>
  <dd className="mt-2 pl-5 md:pl-20 text-white">
    <Link title="Términos y condiciones del servicio prestado por Globaldir Abogados Ecuador" to={"/terminos-y-condiciones"}>Términos y Condiciones del Servicio</Link>
  </dd>
</dl>

    </div>
        <ScrollRestoration />
        <Scripts />
        
      </body>
    </html>
  );
}
